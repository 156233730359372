import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate } from "react-router-dom";
import "react-pdf/dist/esm/Page/TextLayer.css"; // Import required styles
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import styles from "../Guilds/GuildsCatalogue.module.css";
// Set up the worker
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.mjs`;
function GuildsCatalogue() {
  const history = useNavigate();
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [pdfFile] = useState("/assets/showroom/se/SE-GUILDS.pdf");

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePrevious = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNext = () => {
    setPage((prevPage) =>
      numPages ? Math.min(prevPage + 1, numPages) : prevPage
    );
  };

  return (
    <div className='container-fluid p-0' style={{ overflowY: "hidden" }}>
      <div className=' row'>
        <div
          className={styles["btn-back-envirtual-container"] + " col-md-12"}
          onClick={() => {
            history("/showroom/seguildshall");
          }}
        >
          <img src='/assets/back-btn.svg' className={styles["ddl"]}></img>
          <label className={styles["back-btn-label"]}>Back</label>
        </div>
        <img
          src='/assets/showroom/mainhall/ic-bg14.png'
          className='mentor-card-screen-background p-0'
          alt=''
        />
        <div className='col-md-3'></div>

        <div className='col-md-6 p-5 col-sm-12'>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 className={styles["text-shadow"]} style={{ color: "white" }}>
              Coming Soon
            </h1>
          </div>
        </div>

        <div className='col-md-3'></div>
      </div>
    </div>
  );
}

export default GuildsCatalogue;
