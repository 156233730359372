import axios from "axios";
import React from "react";
import { getCurrentUser } from "../../../services/authService";
function ITTSHall() {
  React.useEffect(() => {
    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(
              `${process.env.REACT_APP_SERVER_URL}stats/setpage/IT-TSHall`,
              {
                id: res.data._id,
              }
            )
            .then((res) => {});
        }
      });
  }, []);
  return (
    <a-scene
      cursor='rayOrigin: mouse'
      renderer='gammaOutput: true;antialias: true; colorManagement: true;
          sortObjects: true; '
    >
      <a-entity
        class='listenonkey'
        id='rig'
        position='-5 8 0'
        rotation='0 -90 0'
      >
        <a-camera
          id='camera'
          position='0 0 0'
          wasd-controls-enabled='false'
        ></a-camera>
      </a-entity>

      <a-light
        light='type: spot; angle: 180;'
        color='white'
        position='0 0 0'
        intensity='0.5'
      ></a-light>

      {/* <a-entity light='type: ambient;intensity:0.5'></a-entity> */}
      <a-entity
        light='type:directional; 
                   shadowCameraVisible: false; '
        position='1 35 1'
      ></a-entity>

      <a-entity
        id='model'
        position='0 0 0'
        scale='5 5 5'
        gltf-model='url(/assets/showroom/general/p-comingsoon.glb)'
        animation-mixer='clip:*;loop: infinity;'
        shadow='receive: true'
      ></a-entity>
      {/* <a-sky src='/assets/Hall1small.png' rotation='0 -90 0'></a-sky> */}

      {/* <a-text
        id='texttest1'
        width='1.5'
        value='Click here to'
        material='color:white'
        position='-3.13  2.26  -2.5'
        rotation='0 0 0'
        cursor-url='url:./productivityContactUs'
      ></a-text> */}
      {/* <a-text
        id='texttest1'
        width='1.3'
        value='Contact the team'
        material='color:white'
        position='-3.13  2.2  -2.5'
        rotation='0 0 0'
        cursor-url='url:./productivityContactUs'
      ></a-text> */}

      <a-entity
        id='texttest'
        geometry='primitive: box'
        material='transparency:true;opacity:0.0'
        //material='color:white'
        position='6 8 0'
        rotation='0 80 0'
        scale='6 4 0.12'
        class='intersectable'
        // light='type: point; intensity: 2.0'
        cursor-outside-url='url:https://vodafone.sharepoint.com/sites/WIVAFiles/_layouts/15/embed.aspx?UniqueId=cb7c25da-388c-4146-a455-8ad7b472f7fa'
      ></a-entity>

      {/* <a-text
        id='texttest1'
        width='1.3'
        value='Know us better'
        material='color:white'
        position='2.05  2.18  -2'
        rotation='0 0 0'
      ></a-text> */}

      <a-entity
        id='texttest'
        geometry='primitive: box'
        material='transparency:true;opacity:0.0'
        //material='color:white'
        position='-16 6 0'
        rotation='0 80 0'
        scale='5 10 0.12'
        class='intersectable'
        // light='type: point; intensity: 2.0'
        cursor-url='url:/showroom/oithall'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='14.77 13.56 11.216'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='11.15 13.56 14.75'
        rotation='-90 0 -90'
      ></a-entity>

      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='18 13.56 2'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='18 13.56 -2'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='14.77 13.56 -11.216'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='11.15 13.56 -14.75'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='2.7 13.56 -18'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='-2.2 13.56 -18'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='2.7 13.56 18'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='-2.2 13.56 18'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='-14.77 13.56 11.216'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='-11.15 13.56 14.75'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='-14.77 13.56 -11.216'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='-11.15 13.56 -14.75'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='-18 13.56 2.5'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='-18 13.56 -2.5'
        rotation='-90 0 -90'
      ></a-entity>
    </a-scene>
  );
}

export default ITTSHall;
